<template>
  <div>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="用户Uid">
        <el-input v-model="form.userId" placeholder="请输入用户Uid"></el-input>
      </el-form-item>
      <el-form-item label="企业id">
        <el-input v-model="form.firmId" placeholder="请输入企业id"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="时间">
        <el-date-picker
          is-range
          format="yyyy-MM-dd"
          clearable
          v-model="form.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="pickerChange"
          style="width: 280px"
        >
        </el-date-picker>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="getData">查询</el-button>
        <el-button type="primary" @click="noserch">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="firmId" label="企业id"> </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">待审核</span>
            <span v-if="scope.row.status == 1">审核成功/转款中</span>
            <span v-if="scope.row.status == 2">审核失败</span>
            <span v-if="scope.row.status == 3">转出成功</span>
            <span v-if="scope.row.status == 4">转出失败</span>
            <span v-if="scope.row.status == 5">转出异常</span>
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" label="卡号"> </el-table-column>
        <el-table-column prop="bankCode" label="银行编码"> </el-table-column>
        <el-table-column prop="accountName" label="账户名"> </el-table-column>
        <el-table-column prop="bankInfo" label="银行信息"> </el-table-column>
        <el-table-column prop="cnapsCode" label="大额行号"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="orderSn" label="订单号"> </el-table-column>
        <el-table-column prop="user_id" label="提现用户id"> </el-table-column>
        <el-table-column prop="amount" label="提现金额"> </el-table-column>
        <el-table-column prop="bankId" label="银行卡id"> </el-table-column>
        <el-table-column prop="actualAmount" label="充值提现金额"> </el-table-column>
        <el-table-column prop="serverChange" label="手续费"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope" v-if="scope.row.status == 0">
            <el-button @click="editRow(scope.row, 1)" type="text">通过</el-button>
            <el-button style="color: red" @click="editRow(scope.row, 2)" type="text">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        userId: '',
        status: '',
        firmId: '',
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      options: [
        {
          value: '0',
          label: '待审核'
        },
        {
          value: '1',
          label: '审核成功/转款中'
        },
        {
          value: '2',
          label: '审核失败'
        },
        {
          value: '3',
          label: '转出成功'
        },
        {
          value: '4',
          label: '转出失败'
        },
        {
          value: '5',
          label: '转出异常'
        }
      ],
      tableData: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    //提现列表
    getData() {
      this.axios.get('/admin/firmWithdraw/adminList', this.form).then(res => {
        this.tableData = res.data.data.records
        this.total = res.data.data.total
        console.log(res)
      })
    },
    // 重置
    noserch() {
      this.form.userId = ''
      this.form.status = ''
      this.form.firmId = ''
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getData()
    },
    editRow(row, type) {
      const that = this
      console.log(row, type)
      this.$prompt('请输入备注', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          if (type == 2) {
            if (value == null) {
              this.$message.error('请输入备注')
            } else {
              this.axios.get('/admin/firmWithdraw/updateStatus', { failedReason: value, id: row.id, status: 2 }).then(res => {
                this.$message({
                  message: '审核成功',
                  type: 'success'
                })
                that.getData()
              })
            }
          }
          if (type == 1) {
            this.axios.get('/admin/firmWithdraw/updateStatus', { failedReason: value, id: row.id, status: 1 }).then(res => {
              console.log(res)
              if (res.data.code !== 200) {
                this.$message.error(res.data.data)
              } else {
                this.$message({
                  message: '审核成功',
                  type: 'success'
                })
                that.getData()
              }
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消审核'
          })
        })
    },

    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.form.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination {
  padding: 60px 0 0 110px;
}
</style>